<template>
  <div>
    <BaseDialog
      :show="true"
      width="566px"
      title="兌換卷已建立"
      btn1="確認"
      @confirm="confirm"
      @cancel="$emit('close')"
      @close="$emit('close')"
    >
      <div class="wrapper">
        兌換卷已建立，請確認是否前往序號管理進行設定。
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'CouponExchangeDialog',
  components: { BaseDialog },
  props: ['couponId'],
  setup (props) {
    const router = useRouter()
    const confirm = () => {
      router.push({
        name: 'CouponExchangeSerial',
        params: { id: props.couponId },
      })
    }

    return {
      confirm,
    }
  },
})
</script>

<style lang="postcss" scoped>
.wrapper {
  @apply pb-[30px];
}
</style>
